<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }} Level</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="level.title"
                    :error="$v.level.title.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Title <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.level.title.$error"
                    >This Title Field is required</span
                  >
                </v-col>

                <v-col cols="12">
                  Status
                  <v-switch
                    v-model="level.is_active"
                    :label="level.is_active ? 'Yes' : 'No'"
                    outlined
                    dense
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="closeDialog"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import LevelService from "@/service/level/LevelService";

const level = new LevelService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      imgUrl: null,
      level: {
        title: "",
        is_active: true
      },
      errors: []
    };
  },
  validations: {
    level: {
      title: { required }
    }
  },
  methods: {
    createLevel() {
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editLevel(level) {
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.level = level;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.$reset();
      this.level = {
        title: "",
        is_active: true
      };
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 6000);
      } else {
        if (this.edit) {
          level
            .update(this.level.id, this.level)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Level updated successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        } else {
          level
            .store(this.level)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Level created successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        }
      }
    }
  },
  computed: {
    title() {
      if (this.edit != null || this.edit != undefined) {
        return "Edit";
      } else {
        return "Create";
      }
    }
  }
};
</script>
